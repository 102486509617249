import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import { convertRankFromRomanToNumber } from "@/game-lol/utils/rank-utils.mjs";
import { tierToAbbr } from "@/game-lol/utils/util.mjs";
import { HYPER_ROLL_RANKS } from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";

const RankedInfo = styled("div")`
  display: flex;
  align-items: center;

  svg {
    height: var(--sp-5);
    width: var(--sp-5);
    margin: 0 var(--sp-1) 0 calc(-1 * var(--sp-0_5));
  }
`;
const RankedText = styled("div")`
  ${({ $inline }) =>
    !!$inline &&
    `
      display: flex;
    `}

  .lp {
    color: var(--shade3);
    margin-left: var(--sp-2);
  }
`;

const RankIcon = styled("img")`
  height: var(--sp-5);
  width: var(--sp-5);
  margin: 0 var(--sp-1) 0 calc(-1 * var(--sp-0_5));
`;

const LiveGameRank = ({ ranks = [], inline, showLP, queueType }) => {
  const { t } = useTranslation();
  const league = ranks[0];
  const lp = league?.league_points;

  const isHyperRoll = queueType === "TEAMFIGHT_TACTICS_HYPER_ROLL";

  if (!league) return null;

  if (isHyperRoll) {
    const rank = HYPER_ROLL_RANKS[league.rated_tier];
    if (!rank) return null;
    return (
      <RankedInfo>
        <RankIcon src={StaticTFT.getHyperRollRankImage(rank.key)} />
        <RankedText className="type-caption" $inline={inline}>
          {t(...rank.t)}
          <span className="lp">{league.rated_rating}</span>
        </RankedText>
      </RankedInfo>
    );
  }
  const RankedIcon = getHextechRankIcon(league.tier?.toLowerCase());
  return (
    <RankedInfo>
      <RankedIcon />
      <RankedText className="type-caption" $inline={inline}>
        {tierToAbbr(league)}
        {convertRankFromRomanToNumber(league.rank)}
        {showLP && (
          <span className="lp">
            {t("lol:leaguePoints", "{{points}} LP", {
              points: lp >= 0 ? lp : 0,
            })}
          </span>
        )}
      </RankedText>
    </RankedInfo>
  );
};

export default LiveGameRank;
