import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";
import { regionsToServices } from "@/game-lol/utils/util.mjs";
import LiveGameRank from "@/game-tft/LiveGameRank.jsx";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const PlayerFrame = styled(Row)`
  justify-content: space-between;
  position: relative;
  height: var(--sp-16);
  padding: 0 var(--sp-4);
  background: var(--shade8);
  border-radius: var(--br-sm);
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition: var(--transition);

  &.selected-player {
    background: var(--shade6);
  }
`;

const SummonerIconBackground = styled("div")`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--sp-20);
  background-size: 150px auto;
  background-position: center;
  ${({ $image }) => $image && `background-image: url(${$image})`};
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent);
`;

const UserIndicator = styled("div")`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: var(--yellow);
`;

const SummonerIcon = styled("img")`
  box-sizing: border-box;
  height: var(--sp-11);
  width: var(--sp-11);
  margin-right: var(--sp-3);
  background: var(--shade9);
  border: var(--sp-0_5) solid var(--shade5);
  border-radius: 50%;
  ${({ $isUser }) => $isUser && `border-color: var(--yellow);`}
`;

const SummonerName = styled("div")`
  color: ${({ $isUser }) => ($isUser ? "var(--yellow)" : "var(--shade0)")};
`;

const LiveGamePlayer = ({
  player,
  isUser,
  icon,
  queueType,
  seasons,
  index,
  onClick,
  className,
  region,
}) => {
  const { account } = player || {};

  if (!account) return null;

  const summonerIcon = `${appURLs.CDN}/blitz/lol/profileicon/${
    icon || 29
  }.webp`;

  return (
    <a
      href={`/tft/profile/${regionsToServices(region)}/${account.game_name}-${account.tag_line}`}
    >
      <PlayerFrame data-player={index} onClick={onClick} className={className}>
        <SummonerIconBackground $image={summonerIcon} />
        {isUser === true && <UserIndicator />}
        <Row>
          <SummonerIcon $isUser={isUser} src={summonerIcon} />
          <div>
            <SummonerName className="type-body2-form--active" $isUser={isUser}>
              {account.game_name}

              <span className="shade1 type-caption"> #{account.tag_line}</span>
            </SummonerName>

            <LiveGameRank
              inline
              showLP
              queueType={queueType}
              seasons={seasons}
              ranks={player.league_tft}
            />
          </div>
        </Row>
      </PlayerFrame>
    </a>
  );
};

export default LiveGamePlayer;
