import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { CardInner } from "@/game-lol/components/CommonComponents.jsx";
import LiveGamePlayer from "@/game-tft/LiveGamePlayer.jsx";
import tftRefs from "@/game-tft/refs.mjs";
import { CardContainer } from "@/shared/Profile.style.jsx";
import useDOMElement from "@/util/use-dom-element.mjs";

const Title = styled("div")`
  margin-bottom: var(--sp-2);
`;

const PlayersFrame = styled(CardContainer)`
  margin: 0;
  height: 100%;
  width: 100%;

  .wrapper {
    min-width: calc(var(--sp-1) * 70);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-2);
    flex-grow: 1;
  }
`;

const LiveGamePlayers = ({ players, region, selectedPlayer, queueType }) => {
  const { t } = useTranslation();

  const inGamePlacement = useDOMElement(tftRefs.inGamePlacement);

  return (
    <PlayersFrame>
      <CardInner>
        <Title className="type-title--bold">
          {t("tft:lobbyPlayers", "Lobby Players")}
        </Title>
        <div className="flex row gap-6">
          <div className="wrapper">
            {players.map((player, i) => {
              if (!player) return null;
              const { account, league_tft, summoner } = player;
              if (!account) return null;
              return (
                <LiveGamePlayer
                  key={i}
                  index={i}
                  player={player}
                  isUser={player.isPlayer}
                  icon={summoner.profile_icon_id}
                  ranks={league_tft}
                  region={region}
                  queueType={queueType}
                  className={
                    i === Number.parseInt(selectedPlayer, 10) &&
                    "selected-player"
                  }
                />
              );
            })}
          </div>
          {inGamePlacement}
        </div>
      </CardInner>
    </PlayersFrame>
  );
};

export default LiveGamePlayers;
