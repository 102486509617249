import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  IS_APP,
  IS_DEV_QUERY,
  IS_NODE_BROWSERLESS,
} from "@/__main__/constants.mjs";
import { QUEUE_TYPES } from "@/game-lol/constants/constants.mjs";
import { separateDerivedRiotID } from "@/game-lol/utils/derived-id.mjs";
import { parseInProgressData } from "@/game-lol/utils/in-game-util.mjs";
import { CompFilters } from "@/game-tft/Comps.jsx";
import CompsBlitzAnalyzed from "@/game-tft/CompsBlitzAnalyzed.jsx";
import EarlyComps from "@/game-tft/CompsEarly.jsx";
import LiveGamePlayers from "@/game-tft/LiveGamePlayers.jsx";
import Container from "@/shared/ContentContainer.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import PageHeaderLinks from "@/shared/PageHeaderLinks.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function LiveGame() {
  const {
    parameters: [region, name],
    searchParams,
    currentPath,
  } = useRoute();
  const [localGameName, localTagLine] = separateDerivedRiotID(name);
  const state = useSnapshot(readState);
  const [selectedPlayer, setSelectedPlayer] = useState("all");
  const summoners = state.tft.accounts_v2;
  const LoLLoadingState = state.volatile.LoLLoadingState;
  const InProgressData = useMemo(
    () => parseInProgressData(LoLLoadingState),
    [LoLLoadingState],
  );
  const { t } = useTranslation();
  const isEarly = searchParams.get("early") === "true";

  const queue = readState?.settings?.lol?.lastQueueId;
  const queueType = QUEUE_TYPES[queue];

  const [playersSorted, setPlayersSorted] = useState([]);

  useEffect(() => {
    if ((!IS_APP && !IS_NODE_BROWSERLESS) || IS_DEV_QUERY) {
      const LIVE_FIXTURE = async () => {
        const data = await import(
          "@/game-tft/fixtures/tft-live-game-fixture.json"
        );
        setPlayersSorted(data?.default);
      };
      LIVE_FIXTURE();
      return;
    }

    const allPlayers = []
      .concat(InProgressData.teammates)
      .concat(InProgressData.enemyTeam);

    const playerPromises = allPlayers.map(({ summonerId }) => {
      if (summonerId) {
        const summoner = summoners[summonerId];
        if (summoner && !(summoner instanceof Error)) {
          return {
            ...summoner,
            isPlayer:
              localGameName === summoner.account.game_name &&
              localTagLine === summoner.account.tag_line,
          };
        }
      }
      return null;
    });

    Promise.all(playerPromises).then((results) => {
      const filteredResults = results.filter((result) => result !== null);
      setPlayersSorted(filteredResults);
    });
  }, [
    InProgressData.enemyTeam,
    InProgressData.teammates,
    localGameName,
    localTagLine,
    summoners,
  ]);

  const isPlayerData = useMemo(() => playersSorted.length > 4, [playersSorted]);

  const links = useMemo(() => {
    return [
      {
        url: `${currentPath}`,
        text: t("tft:common.topComps", "Top Comps"),
        routeMatch: /^(?!.*early=true).*/,
      },
      {
        url: `${currentPath}?early=true`,
        text: t("tft:common.earlyComps", "Early Comps"),
        routeMatch: /(?:\?|&)early=true(?:&|$)/,
      },
    ];
  }, [currentPath, t]);

  const Comps = useMemo(
    () => (isEarly ? EarlyComps : CompsBlitzAnalyzed),
    [isEarly],
  );

  return (
    <Container
      className="relative flex column gap-2"
      style={{ marginBottom: "var(--sp-20)" }}
    >
      {isPlayerData && (
        <div className="flex gap-sp-3">
          <LiveGamePlayers
            players={playersSorted}
            region={region}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
            queueType={queueType}
          />
        </div>
      )}
      {matchRefs.InContentBanner && <matchRefs.InContentBanner center={true} />}
      <PageHeaderLinks links={links} className="headerLinks" />
      <CompFilters />
      <Comps />
    </Container>
  );
}

export function meta(parameters) {
  return {
    title: ["tft:helmet.livegame.title", "TFT Live Game – Blitz TFT"],
    description: [
      "tft:helmet.livegame.description",
      `${parameters[1]}'s Live game - Teamfight Tactics TFT Items Statistics, win rates, average place, top 4, matches`,
    ],
  };
}
